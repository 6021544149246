.containerSlider {
  display: 'inline';
  height: '72px';
  width: '100%';
  align-items: 'center';
}

.branchSlider {

  .slick-track {
    height: 72px;
    display: flex;
    align-items: center;
  }

  .slick-track .slick-slide div {
    display: inherit !important;
    align-items: center !important;
    justify-content: center !important;
  } 

  .slick-slide{
    margin-left:  36px;
    margin-right:  36px;
  }

  .slick-slide div{
    width: 200px !important;
  }

  .slick-slide img{
    display: inherit !important;
    justify-content: center !important;
  }
}

.slick-initialized .slick-slide {
  display: inherit !important;
  align-items: center !important;
}




