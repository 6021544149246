.container-plataforma-mit {
  width: 558px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;
  transition: all 0.3s;
}

.container-mitec {
  width: 558px;
  background-color: #fff;
  padding: 16px;
  border: 1px solid #c7c7c7;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 12%);
  line-height: 22px;
  color: #000;
  transition: all 0.3s;
}

.titulo-plataforma-mit {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding: 10px;
}

.info-plataforma-mit {
  background-color: #e6f3fa;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 5px 0 15px;
  transition: all 0.3s;
}

.text-plataforma-mit {
  color: #013654;
  font-weight: 500;
  font-size: 12px;
}

.icon-info {
  width: 22px;
  height: auto;
  margin: 10px;
  transition: all 0.3s;
}

.container-iframe-mitec {
  border: none;
}

@media (max-width: 767px) {
  .container-plataforma-mit {
    width: 558px;
    box-shadow: none;
  }
  .container-mitec {
    width: 100%;
  }
}

@media (max-width: 560px) {
  .container-plataforma-mit, .container-mitec {
    width: 90%;
  }
  .container-mitec {
    width: 100%;
  }
  .icon-info {
    margin: 5px;
  }
  .info-plataforma-mit {
    padding: 10px 5px;
  }
}