
#slider {
    position: absolute;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    height: auto;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
}


.slide-in {
    animation: slide-in 2s forwards;
    -webkit-animation: slide-in 2s forwards;
}

.slide-out {
    animation: slide-out 2s forwards;
    -webkit-animation: slide-out 2s forwards;
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}



/* pantalla grande  */
@media only screen  and (min-width: 1440px) and (max-width: 10000px) {
    .content-pay {
        flex-direction: column;
        padding: 15px 302px 10px 10px;
        height: auto!important;
        width:auto!important;
        background-color: #F4F4F4 !important;
    }
    .card-content-form-pay{
        width: 805px!important;
    }
    .card-detail-plan-pay {
        width: 315px;

    }
    .card-folio-pay{
        width: 360px!important;
    }
    .card-img-mobile {
        display: none!important;
    }
}

/* Escritorio grande */
@media only screen and (min-width: 1025px)  and (max-width: 1440px) {}

/* Laptop */
@media only screen and (min-width: 821px) and (max-width: 1024px) {}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
    #slider {
        position: absolute;
        padding-left: 5px;
    }
}

/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {
    #slider {
        position: absolute;
        padding-left: 5px;
    }
}

/* Teléfonos */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    #slider {
        position: absolute;
        padding-left: 5px;
    }
}

