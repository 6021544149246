/* Header */
.blog-container {
  height: 525px;
}

.blog-img {
  width: 183px;
  height: 550px;
}

.blog-first-title {
  color: #fff; /* Color del texto (blanco) */
  text-shadow: -1px -1px 0 #039ecc, 1px -1px 0 #039ecc, -1px 1px 0 #039ecc,
    1px 1px 0 #039ecc;
  font-size: 70px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-right: 5rem;
  position: relative;
  left: -1.5rem;
  bottom: 1.1rem;
}

.blog-second-title {
  color: var(--azul-inter, #039ecc);
  font-size: 70px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  position: relative;
  top: 1rem;
}

.blog-description {
  position: relative;
  background: var(--azul-inter, #039ecc);
  display: flex;
  height: 120px;
  padding: 24px 247px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;

  & p {
    font-weight: 700;
  }
}

/* Body */
.txt-bold {
  font-weight: 700 !important;
}

.blog-grid-border {
  border-bottom: 1px solid var(--black-25, #C7C7C7);
}

.blog-tags-container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  text-align: center;
}

.blog-tag-txt {
  font-size: 14px !important;
}

.blog-first-tag {
  border-radius: 8px;
  color: white;
  background: var(--orange, #E39217);
  /* padding: 2px 16px; */
  text-transform: uppercase;
  width: 133px;
  height: 21px;
}

.blog-second-tag {
  border-radius: 8px;
  color: white;
  background: #90268D;
  /* padding: 2px 16px; */
  text-transform: uppercase;
  width: 133px;
  height: 21px;
}

.blog-img-promo {
  position: relative;
  display: inline-block;
  cursor: pointer;

  & img {
    display: block;
    max-width: 100%;
  }

  & span {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: white;
    border-radius: 50%;
    padding: 1px;
  }
}

@media screen and (max-width: 500px) {
  .blog-container {
    height: 540px;
    padding-bottom: 2rem;
  }

  .blog-img {
    width: 173px;
    height: 540px;
  }

 
  .blog-first-title {
    font-size: 46px;
    left: 8rem;
    bottom: 4rem;
    width: 400px;
  }

  .blog-second-title {
    font-size: 46px;
    top: -1rem;
    right: 9rem;
  }

  .blog-description {
    width: auto;
    padding: 0 1rem;
  }
}