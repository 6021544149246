.container-succRen {
    padding: 0 !important;
    margin: 0 !important;
    max-width: inherit !important;
}

.tipography-h5 {
    font-weight: 700 !important;
    font-style: normal !important;
    line-height: normal !important;
    text-align: center !important;
}

.tipography-regular {
    font-weight: 400 !important;
    font-style: normal !important;
    line-height: normal !important;
    text-align: center !important;
}

.container-button {
    height: 48px;
    gap: 10px;
    border-radius: 10px !important;
    background: #039ECC !important;
    /* opacity: 0.8 !important; */
    color:#FFF !important;
}

.container-button:hover{
    background: #026E8E !important;
    color:#FFF !important;
}

.tipography-button {
    font-family: Montserrat !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal !important;
    text-transform: uppercase;
}

