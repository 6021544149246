/* Estilos para el contenedor */
.preguntas-frecuentes-container {
  height: 400px;
}

/* Estilos para la capa de máscara */
.preguntas-frecuentes {
  left: -35vh;
  position: relative;
}

.peguntas-frecuentes-img {
  width: 781px;
  height: 457px;
}

/* Estilos para el texto con contorno */
.preguntas {
  font-size: 68px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #fff; /* Color del texto (blanco) */
  text-shadow: -1px -1px 0 #039ecc, 1px -1px 0 #039ecc, -1px 1px 0 #039ecc,
    1px 1px 0 #039ecc;
  margin-right: 1rem;
}

.frecuentes {
  color: var(--azul-inter, #039ecc);
  font-size: 68px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.descripcion-preguntas {
  position: relative;
  background: var(--azul-inter, #039ecc);
  display: flex;
  height: 120px;
  padding: 24px 247px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;

  & p {
    font-weight: 700;
  }
}

.border-buscador {
  border: 1px solid #212121;
}

/* Estilos de la card */
.question-container {
  /* padding-bottom: 1rem; */
  margin: 0.5rem 3rem;
  box-shadow: none;
  border-bottom: 1px solid #c7c7c7;
}

.tags-container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.first-tag {
  border-radius: 8px;
  color: white;
  background: var(--black-25, #c7c7c7);
  padding: 2px 16px;
  text-transform: uppercase;
}

.second-tag {
  border-radius: 8px;
  color: white;
  background: var(--azul-50, #77cae3);
  padding: 2px 16px;
  text-transform: uppercase;
}
.preguntas-container {
  height: 100%;
}

.display-height {
  height: 60vh;
}

/* Ajustamos los estilos de la imagen segun la resolución de pantalla */
@media screen and (max-width: 500px) {
  .preguntas-frecuentes-container {
    display: contents;
    height: 350px;
  }

  .pf-container {
    height: 400px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }

  .pf-titles {
    padding-top: 3rem;
  }

  .preguntas {
    font-size: 45px;
    padding: 0;
    margin: 0;  
  }

  .frecuentes {
    font-size: 45px;
    padding: 0;
    margin: 0;
  }

  .peguntas-frecuentes-img {
    width: 120% !important;
    max-width: 120% !important;
    height: 65%;
    top: 6rem;
  }

  .descripcion-preguntas {
    width: auto;
    padding: 0 1rem;
  }
}

