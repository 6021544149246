
.container-info {
  display: flex;
  height: 100%;
  max-height: 1200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.pago-recibido-titulo {
  font-weight: 700;
  font-size: 25px;
  width: 575px;
  text-align: center;
  color: #212121;
  margin-top: 15px;
  margin-bottom: 5px;
}

.pago-recibido-info {
  font-size: 16px;
  font-weight: 500;
  width: 520px;
  text-align: center;
  color: #013654;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pago-recibido-info-telefono {
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: #039ECC;
}

.img-info {
  display: flex;
  height: auto;
  width: 350px;
  min-height: 350px;
  margin: 10px;
  transition: all 0.3s;
}

.boton-action {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  margin-top: 15px;
  background-color: #0083AA;
  color: #fff;
  border-radius: 25px;
  width: 350px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-tap-highlight-color: transparent; /* Elimina el resaltado azul */
  outline: none;
}

.boton-action:hover {
  background-color: #007699;
  border-color: #007699;
  box-shadow: none;
}

.boton-action:active {
  background-color: #006c8d;
  border-color: #006c8d;
  box-shadow: none;
}

.boton-action:focus {
  box-shadow: none;
  outline: none;
}

.boton-action:disabled {
  color: #a6a6a6;
  background-color: #e0e0e0;
  box-shadow: none;
}

@media (max-width: 600px) {
  .pago-recibido-info {
    width: 90%;
  }
  .img-info {
    width: 250px;
    min-height: 250px;
  }
  .pago-recibido-titulo {
    width: 90%;
  }
}