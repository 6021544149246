
.container-pago-exitoso {
  display: flex;
  flex-direction: column;
  align-items: center;
  translate: all 0.3s;
}

.pago-exitoso-titulo {
  font-weight: 700;
  font-size: 25px;
  width: 575px;
  text-align: center;
  color: #212121;
  margin-top: 15px;
  margin-bottom: 5px;
}

.pago-exitoso-info {
  font-size: 18px;
  font-weight: 500;
  width: 575px;
  text-align: center;
  color: #013654;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pago-exitoso-info-telefono {
  font-size: 18px;
  font-weight: 700;
}

.img-pago-ok {
  display: flex;
  height: auto;
  width: 250px;
  margin: 10px;
  transition: all 0.3s;
}

@media (max-width: 600px) {
  .pago-exitoso-info {
    width: 90%;
  }
  .pago-exitoso-titulo {
    width: 90%;
  }
}