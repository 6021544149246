/* Estilos para el texto con contorno */
.aviso {
  font-weight: 900 !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #fff;
  /* Color del texto (blanco) */
  text-shadow: -1px -1px 0 #039ecc, 1px -1px 0 #039ecc, -1px 1px 0 #039ecc,
    1px 1px 0 #039ecc;
  margin-right: 1rem;
}

.privacidad {
  color: var(--azul-inter, #039ecc);
  font-weight: 900 !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.header-avisoinfo {
  color: var(--black-75, #595959);
  font-family: Montserrat;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* text-align: justify; */
}


.info {
  color: var(--black, #212121);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: justify;
}

.descripcion-aviso {
  position: relative;
  background: var(--azul-inter, #039ECC);
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}