.container-footer-recibos {
  display: flex;
  width: 100%;
  padding: 0;
  align-items: center;
  background: #FFF;
  margin-top: 1rem;
  flex-grow: 0;
  justify-content: center;
  z-index: 1205;
}

.footer-copyright {
  width: 558px;
  padding: 0.6rem 1rem;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgb(143 143 143 / 20%);
  transition: all 0.2s;
}

.footer-copyright > label {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #8f8f8f;
  text-align: center;
}

@media (max-width: 767px) {
  .footer-copyright {
    width: 480px;
  }
}

@media (max-width: 480px) {
  .footer-copyright {
    width: 95%;
  }
}