*{
    padding:0;
    margin:0;
    /* box-sizing: border-box; */
    font-family: "Montserrat";
}

.slick-slide div{
    margin:0 1 rem;
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    line-height: 1;
    opacity: .75;
    color: #039ECC !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before {
    content:url('../../assets/img/icons/leftVector.svg') !important;
    position: relative !important;
    left:-145px !important;
}

@media only screen and (min-width: 300px) and (max-width: 820px) {
    .slick-prev:before, .slick-next:before {
        display:none !important;
    }
}

.slick-next:before{
    content:url('../../assets/img/icons/rightVector.svg') !important;
    position: relative !important;
    right: -105px !important;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: 0 !important;
    /* display: none !important; */
}

.slick-dots li.slick-active button:before{
    color:#039ECC !important;
    border-radius: 10px;
    width: 15px !important;
    height: 10px !important;
    opacity: 1 !important;
}

.slick-dots li button:before{
    color:#D9D9D9 !important;
    font-size: 10px !important;
    top:2rem !important;
    width: 15px !important;
    height: 10px !important;
    opacity: 1 !important;
    border-radius: 10px !important;
}
