.container-inactividad {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

}

.inactividad-info {
    font-size: 20px;
    margin: 10px 8px 15px;
    text-align: center;
}