.container-datos {
  width: 558px;
  background-color: #fff;
  /*padding: 16px;*/
  /*border: 1px solid #c7c7c7;*/
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #000;
  transition: all 0.3s;
}

.container-recibos {
  /*width: 558px;*/
  background-color: #fff;
  padding: 16px;
  border: 1px solid #c7c7c7;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 12%);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #000;
  transition: all 0.3s;
}

.container-montos {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s;
}

.titulo-plataforma-mit {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding: 10px 10px 20px;
}

.container-montos:hover {
  background: rgb(249 249 249 / 50%);
  border-radius: 4px;
}

.logo-aseguradora {
  height: 35px;
  width: auto;
  margin-bottom: 15px;
  margin-top: 8px;
}

.numero-cuota {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 4px;
  margin-top: 8px;
  color: #595959;
}

.monto-total {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 5px;
  margin-top: 4px;
  color: #212121;
}

.monto-titulo {
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 4px;
  margin-top: 4px;
  color: #595959;
}

.status-recibo {
  background-color: #ffe0e3;
  padding: 4px 15px;
  border-radius: 16px;
  font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: #CD0505;
  margin-top: 4px;
  margin-bottom: 8px;
}

.status-pendiente {
  color: #cd8705;
  border-color: #cd8705;
  background-color: #fff2da;
}

.status-pagado {
  color: #00990d;
  border-color: #00990d;
  background-color: #ebfded;
}

.status-cancelado {
  color: #CD0505;
  border-color: #CD0505;
  background-color: #ffe0e3;
}

.status-desconocido {
  color: #5e5e5e;
  border-color: #5e5e5e;
  background-color: #F5F5F5;
}

.container-descripcion {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  margin-top: 6px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  border-top: 2px solid #ededed;
  border-bottom: 2px solid #ededed;
}

.row-detalle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
}

.row-detalle:hover {
  background: #f9f9f9;
  border-radius: 4px;
}

.titulo-detalle {
  width: auto;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  color: #8F8F8F;
  padding: 4px 4px;
  white-space: nowrap;
}

.valor-detalle {
  width: auto;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 700;
  color: #595959;
  padding: 4px 4px;
  text-align: right;
}

.container-acciones {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 5px;
}

.form-check-future-payment {
  background-color: transparent !important;
  color: rgb(0 0 0 / 35%) !important;
  margin-right: 0 !important;
  align-self: self-start;
}

.form-check-future-payment span:first-of-type {
  background-color: transparent !important;
}

.form-check-future-payment span:last-of-type {
  background-color: transparent !important;
  color: #595959 !important;
  font-size: 15px;
}

.boton-proceder {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  margin-top: 15px;
  background-color: #0083AA;
  color: #fff;
  border-radius: 25px;
  width: 350px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-tap-highlight-color: transparent; /* Elimina el resaltado azul */
  outline: none;
}

.boton-proceder:hover {
  background-color: #007699;
  border-color: #007699;
  box-shadow: none;
}

.boton-proceder:active {
  background-color: #006c8d;
  border-color: #006c8d;
  box-shadow: none;
}

.boton-proceder:focus {
  box-shadow: none;
  outline: none;
}

.boton-proceder:disabled {
  color: #a6a6a6;
  background-color: #e0e0e0;
  box-shadow: none;
}

.aviso-pago-ref {
  color: #595959;
  font-size: 14px;
}

@media (max-width: 767px) {
  .container-datos {
    width: 558px;
    box-shadow: none;
  }
}

@media (max-width: 560px) {
  .container-datos {
    width: 90%;
  }
  .boton-proceder {
    width: 90%;
  }
  .form-check-future-payment span:last-of-type {
    font-size: 14px;
  }
  .titulo-detalle {
    white-space: normal;
  }
}