html {
  font-family: Montserrat !important;
}
.font-serif {
  font-family: "Montserrat" !important;
}
.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.bg-shadow {
  background: linear-gradient(
    #039ecc 5%,
    transparent 95%
  ); /*rgba(255, 255, 255, 0.4) 95%)*/
}

.bg-gray-50 {
  background-color: #f4f4f4;
}

.z-100 {
  z-index: 100;
}

.icon-product {
  margin: auto;
  height: 185px;
  max-width: 200px;
  border: 1px solid #77cae3;
}

.icon-product:hover {
  box-shadow: 0 3px 1px -2px rgb(119 203 227 / 70%),
    0 2px 2px 0 rgb(119 203 227 / 74%), 0 1px 5px 0 rgb(119 203 227 / 72%);
}

.bg-landingUs {
  position: absolute;
  left: 0;
  top: 64px;
  background-image: url("/src/assets/img/banners/BannerHome-desk.webp");
  height: 480px;
  width: 100%;
}

.containerHeaderUs {
  height: 544px;
  position: relative;
}

.interContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
  margin-top: 80px;
  width: 100%;
  height: 486px;
}
.interTitleMean {
  font-weight: 700;
  font-size: 38px;
  line-height: 44px;
  text-align: center;
  color: #989898;
}
.interDiv {
  height: 318px;
  display: flex;
  justify-content: space-around;
  width: inherit;
  margin-top: 30px;
}
.interObjects {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 215px;
}
.interImage {
  height: 30%;
}
.interSubtitle {
  color: #039ecc;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}
.interDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #262626;
}
.insuranceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}

.insuranceTitle {
  font-weight: 700;
  font-size: 38px;
  color: #989898;
}

.insuranceContainerCards {
  display: flex;
  padding-top: 40px;
}

.insuranceCard {
  width: 295px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.insuranceObjects {
  width: 212px;
  height: 316px;
}

.paraphsCards {
  color: #039ecc;
  font-weight: 700;
  font-size: 27px;
  line-height: 27px;
}

.referenceInsurance {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #262626;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.lastParaph {
  color: #989898;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}
.cardButton {
  background-color: #039ecc;
  width: 212px;
  height: 48px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

.wrap-image {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 267px;
}

.text-header {
  height: 20px;
  color: var(--black, #212121);
  text-align: center;
  justify-content: start;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.textMovil {
  height:20px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  line-height: normal;
}

.bg-cover {
  background-position-x: calc(76%) !important;
}

.bg-bubble-light {
  background-image: url("/src/assets/img/banners/about-us/bubble-light.webp");
}

.bg-bubble-transparent {
  background-image: url("/src/assets/img/banners/about-us/bubble-transparent.png");
}
.semovi {
  margin-top: -35% !important;
}
.alin {
  margin: 40px;
  text-align: justify;
}
.title-promociones {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 50px;
  color: #222222;
  text-align: center;
  width: 100% !important;
}
.txt-empty {
  margin-bottom: 5%;
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 35px;
  color: #222222;
  text-align: center;
  width: 100% !important;
  height: 100% !important;
}

.bg-coche {
  background-image: url("/src/assets/img/bg-coche.jpg");
}
.bg-gmm {
  background-image: url("/src/assets/img/bg-medico.jpg");
}
.bg-mascota {
  background-image: url("/src/assets/img/bg-mascota.jpg");
  background-position-x: calc(33%) !important;
}
.bg-vida {
  background-image: url("/src/assets/img/bg-vida.jpg");
}
.bg-bien {
  background-image: url("/src/assets/img/bg-bien.jpg");
}
.bg-vigo {
  background-image: url("/src/assets/img/bg-vigo.jpg");
}
.bg-promo {
  background-image: url("/src/assets/img/bg-promo.webp");
}

.center-img-cardBody {
  display: grid;
  width: 50%;
}

.cardBody-header {
  display: inline-flex;
  width: 100%;
  min-height: 100px;
}

.cardBody-body {
  font-weight: 400;
  font-size: 20px;
}

.card-height {
  min-height: 300px;
  padding: 8px;
}

.cardBody-header-text {
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 700;
  font-size: 20px;
}
.cardBody-footer {
  display: table-row;
}
.blue {
  color: #039ecc;
}

.svg-autofill {
  width: '100%';
}

.cardBody-frame-img {
  display: grid;
  justify-content: center;
  align-items: baseline;
  height: 75px;
  margin-bottom: -15px;
}

.border-right {
  border-right: 1px solid #b4b4b4;
}

.border-right-promo {
  border-right: 1px solid #c7c7c7;
}

.cardBody-frame-text {
  display: grid;
  margin-top: -10px;
  font-weight: 700;
  font-size: 18px;
  color: #b4b4b4;
}

.team-section-container {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  min-height: 300px;
}

.team-section-text {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.team-section-header {
  font-weight: 700;
  font-size: 20px;
  padding: 10px;
}

.team-section-footer {
  padding: 10px;
}

.sosSection {
  background-color: #fff;
  border-radius: 6px;
  color: #039ecc;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  min-height: 60px;
  min-width: 215px;
}

.phoneSection-header-r {
  font-weight: 700;
  font-size: 14px;
  text-align: right;
  color: #039ecc;
}

.phoneSection-body-r {
  font-weight: 400;
  font-size: 12px;
  text-align: right;
}

.phoneSection-header-l {
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  color: #039ecc;
}

.phoneSection-body-l {
  font-weight: 400;
  font-size: 12px;
  text-align: left;
}

.bg-footer {
  background-color: #282828;
}

.bg-blue-inter {
  background-color: #039ecc;
}

.bg-blue-25-inter {
  background-color: #c0e6f2;
}

.bg-blog-background {
  background-image: url("/src/assets/img/bg-blog.webp");
}

.margin-auto {
  margin: auto;
}

.logos {
  display: inline-flex;
}

.logos > div:first-child {
  padding-right: 15px;
}

.logos-footer {
  display: inline-flex;
}

.logos-footer > a {
  padding-right: 20px;
}

.logos-footer > a:last-child {
  padding-right: 5px;
}

.header-gradient {
  background-color: #f9f9f9;
  width: 100%;
  /* padding-left: 150px;
  padding-right:150px; */
}

.header-gradient a,
.header-gradient .item-menu {
  color: #8f8f8f;
  font-size: 16px;
  font-weight: 600;
}

.gridAlign{
  text-align: center;
}

.header {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  color: var(--black, #212121);
  font-family: Montserrat;
  line-height: normal;
}

.textMovil {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.list-inicio {
  background: #f4f4f4;
}

.case-none {
  text-transform: none !important;
}

.button-login {
  width: 132px;
  height: 40px;
}

.promo {
  padding-left: 3rem;
}

.case-none {
  text-transform: none !important;
}

.logo-inter {
  width: 137.68px;
  height: 32.02px;
}

.box-pq-inter {
  padding: 0px;
}

.box {
  border-radius: 8px;
  min-height: 180px;
  max-height: 100%;
  max-width: 100%;
  background: #ffffff;
}

.w100px {
  width: 100px;
}

.titulo-box-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #039ecc;
}

.desc-box-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.text-align-center {
  text-align: center;
}

.tiendas {
  display: flex;
  justify-content: space-around;
}

.mt-15 {
  margin-top: 15px !important;
}

.pr-16px {
  padding-right: 16px;
}

.header-footer {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  line-height: normal;
}

.body-footer {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  line-height: normal;
}

.footer-footer {
  font-weight: 200;
  font-size: 10px;
  color: #fff;
}

button.MuiButtonBase-root {
  outline: 0 !important;
  text-transform: none;
}

.Banner {
  height: 800px;
  position: relative;
}

.height-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.Media {
  background-color: white;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.MediaCaption {
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  padding: 15px;
  background-color: black;
  color: white;
  opacity: 0.6;
  width: 100%;
  height: 10%;
}

.txt-color-white {
  color: #fff;
}
.font-40 {
  font-size: 2.5rem;
}

.font-22 {
  font-size: 1.375rem;
}

.font-28 {
  font-size: 1.75rem;
}

.font-12 {
  font-size: 0.75rem;
}
.img-full {
  margin: auto;
  width: 800px;
}
.h-coments-mobile {
  height: 400px;
  max-width: 307px;
}
.bg-animate-landing {
  animation-name: bground;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  background-color: #039ecc;
}

.textMobile {
  color: #039ecc;
}

/* @keyframes bground {
  0%   {background-image: url('/src/assets/img/banners/newlanding_home.webp');}
  33%  {background-image: url('/src/assets/img/banners/newlanding_home.webp');}
  66%  {background-image: url('/src/assets/img/banners/newlanding_home.webp');}
  100%   {background-image: url('/src/assets/img/banners/newlanding_home.webp');}
}  */

.txt-caps {
  text-transform: uppercase;
}

.font-32 {
  font-size: 2rem;
}

.font-20 {
  font-size: 1.25rem;
}

.font-16 {
  font-size: 1rem;
}

.font-18 {
  font-size: 1.125rem;
}

.text-5xl {
  font-size: 3.75rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.txt-color-gray {
  color: #c7c7c7;
}

.-mt-544 {
  margin-top: -30rem;
}

.txt-color-gray img {
  opacity: 0.6;
}

.makeStyles-itemWrapper-3 {
  display: flex;
  align-items: center;
}
.height-slideShow {
  display: flex;
  align-items: center;
}

.title-blog {
  font-size: 20px;
  font-weight: 700;
}

.title-body {
  color: #808285;
}

.border-promo {
  border-right: 1px solid #8f8f8f;
}

@media (max-width: 1366px) {
  .container-lg {
    display: none;
  }
  .bg-blog {
    width: 100%;
  }

  .bg-blog img {
    height: inherit;
  }
}

@media (min-width: 1024px) {
  div.lg\:flex {
    display: flex !important;
  }
}

@media (max-width: 1024px) {
  .text-6xl {
    font-size: 3.25rem;
  }
  .text-5xl {
    font-size: 3.25rem;
  }
  .font-40-mobile {
    font-size: 1.75rem;
  }
  .font-32-mobile {
    font-size: 1.25rem;
  }
  .height-banner {
    height: 210px;
  }
  .bg-gmm.bg-cover {
    background-position-x: calc(26%) !important;
  }

  .bg-gmm-promotemp {
    width: 352px;
    height: 215px;
  }

  .wrap-image {
    height: 165px;
  }
}

@media (max-width: 768px) {
  .height-banner {
    height: 160px;
  }
  .wrap-image {
    height: auto;
  }

  .border-promo {
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
  }

  .bg-gmm-promotemp {
    width: 533px;
    height: 325px;
  }
}

@media (min-width: 570px) {
  @keyframes bground {
    0% {
      background-image: url("/src/assets/img/banners/newlanding_home.webp");
    }
    33% {
      background-image: url("/src/assets/img/banners/newlanding_home.webp");
    }
    66% {
      background-image: url("/src/assets/img/banners/newlanding_home.webp");
    }
    100% {
      background-image: url("/src/assets/img/banners/newlanding_home.webp");
    }
  }
}

@media (max-width: 569px) {
  @keyframes bground {
    0% {
      background-image: url("/src/assets/img/banners/newlanding_home_mobile.webp");
    }
    33% {
      background-image: url("/src/assets/img/banners/newlanding_home_mobile.webp");
    }
    66% {
      background-image: url("/src/assets/img/banners/newlanding_home_mobile.webp");
    }
    100% {
      background-image: url("/src/assets/img/banners/newlanding_home_mobile.webp");
    }
  }
}

@media (max-width: 432px) {
  .banner-lg {
    display: none !important;
  }

  .logo-inter {
    width: 113.39px;
    height: 26.37px;
  }

  .box-pq-inter {
    padding: 0px;
  }

  @keyframes bground {
    0% {
      background-image: url("/src/assets/img/banners/newlanding_home_mobile.webp");
    }
    33% {
      background-image: url("/src/assets/img/banners/newlanding_home_mobile.webp");
    }
    66% {
      background-image: url("/src/assets/img/banners/newlanding_home_mobile.webp");
    }
    100% {
      background-image: url("/src/assets/img/banners/newlanding_home_mobile.webp");
    }
  }
}

@media (max-width: 427px) {
  .hide-movil {
    display: none !important;
  }

  .brand-lg {
    display: none !important;
  }

  .height-slideShow {
    height: 16rem !important;
  }

  .text-5xl {
    font-size: 2rem !important;
  }
  .text-6xl {
    font-size: 2rem !important;
  }
  .font-40 {
    font-size: 1.25rem !important;
  }

  .pt-16.landing {
    padding-top: 22rem;
  }
  .height-banner {
    height: calc(100vw * 0.73);
  }
  .bg-mobile-gray .bg-white {
    background-color: #fdfafa;
  }

  .br-mascota {
    border-right: transparent !important;
    border-bottom: 1px solid white !important;
  }
  .css-11btc8q-MuiPaper-root-MuiCard-root {
    min-height: auto !important;
  }
}

.br-mascota {
  border-right: 1px solid white;
  border-bottom: transparent;
}

@media (min-width: 433px) {
  .brand-md {
    display: none !important;
  }
  .banner-md {
    display: none !important;
  }
  .height-slideShow {
    height: 35rem;
  }

  .hide-desktop {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .form-lg {
    display: none !important;
  }
}

/* iPad Air */
@media (width: 820px) and (height: 1180px) {
  .txt-header {
    margin-top: 500px;
  }
}

/* iPhone 13 Pro Max y iPad*/
@media ((width: 428px) and (height: 926px)) or ((width: 768px) and (height: 1024px)) {
  .txt-header {
    margin-top: 300px;
  }
}

/* Samsung Galaxy S20 Ultra e iPhone XR*/
@media ((width: 412px) and (height: 915px)) or ((width: 414px) and (height: 896px)) {
  .txt-header {
    margin-top: 70px;
  }
}

/* iPhone 6/7/8 plus */
@media (width: 414px) and (height: 736px) {
  .txt-header {
    margin-top: 30px;
  }
}

/* Samsung Galaxy A51/71 */
@media (width: 412px) and (height: 914px) {
  .txt-header {
    margin-top: 50px;
  }
}

/* iPhone 12 Pro */
@media (width: 390px) and (height: 844px) {
  .txt-header {
    margin-top: 0px;
  }
}

/* iPhone 4 */
@media (width: 320px) and (height: 480px) {
  .txt-header {
    margin-top: -240px;
  }
}

/* Varios */
@media (min-width: 320px) and (max-width: 390px) and (min-height: 480px) and (max-height: 844px) {
  .txt-header {
    margin-top: -70px;
  }
}

/* iPhone 5/SE */
@media (width: 320px) and (height: 568px) {
  .txt-header {
    margin-top: -250px;
  }
}

/* Galaxy Fold */
@media (width: 280px) and (height: 653px) {
  .txt-header {
    margin-top: -340px;
  }
}

@media (max-width: 768px) {
  .form-md {
    display: none !important;
  }
  .container-md {
    display: none;
  }
  .text-5xl {
    font-size: 3rem;
  }
  .text-6xl {
    font-size: 3rem;
  }
  .font-40 {
    font-size: 1.75rem;
  }
  .height-slideShow {
    height: 22rem;
  }
  .bg-nav {
    background-color: white;
    border-radius: 8px;
  }
  .bg-nav .text-white {
    color: black !important;
  }
  .bg-nav button.text-white {
    background-color: black !important;
    color: white !important;
  }
}

@media (min-width: 768px) {
  .container-md {
    display: none;
  }
}

@media (max-width: 899px) {
  .border-md-none {
    border-right: #fff !important;
  }
  .border-md-right-none {
    border-right: none;
  }
  .border-md-bottom {
    border-bottom: 2px solid #c7c7c7;
  }
}

@media (min-width: 1366px) {
  .container-sm {
    display: none;
  }
}

@media (max-width: 1439px) {
  .mproducts-lg {
    display: none;
  }
}

@media (min-width: 1438px) {
  .mproducts-sm {
    display: none;
  }
}

@media (min-width: 475px) {
  .icons-team-md {
    display: none;
  }
}

@media (max-width: 476px) {
  .icons-team-lg {
    display: none;
  }
}

@media (max-width: 815px) and (max-height: 376px) {
  .text-5xl {
    font-size: 2rem;
  }
  .text-6xl {
    font-size: 2rem;
  }
  .font-40 {
    font-size: 1rem;
  }
  .bg-nav {
    background-color: white;
    border-radius: 8px;
  }
  .bg-nav .text-white {
    color: black !important;
  }
  .bg-nav button.text-white {
    background-color: black !important;
    color: white !important;
  }
  .-mt-32 {
    margin-top: -6rem;
  }
  .bg-mobile-gray .bg-white {
    background-color: #fdfafa;
  }
  .br-mascota {
    border-right: transparent !important;
    border-bottom: 1px solid white !important;
  }
  .-mt-544 {
    margin-top: -8rem !important;
  }
  .css-11btc8q-MuiPaper-root-MuiCard-root {
    min-height: auto !important;
  }
}

@media (max-width: 2560px) {
  .insuranceCard {
    margin: 0 10px;
  }
}

@media (max-width: 769px) {
  .insuranceContainerCards {
    width: 708px;
    height: 390px;
  }
  .insuranceCard {
    width: 225px;
  }
  .interContainer {
    width: 708px;
    height: 788px;
    justify-content: flex-start;
    margin: 0 auto;
  }
  .interDiv {
    flex-wrap: wrap;
    height: inherit;
    width: 600px;
    margin-top: 40px;
  }
  .bg-landingUs {
    background-image: url("/src/assets/img/banners/BannerHome-tablet.webp");
    height: 400px;
  }
  .header {
    width: 768px;
  }
  .logos-footer {
    flex-wrap: wrap;
    height: 80px;
    justify-content: center;
  }
}

@media (max-width: 426px) {
  .bg-landingUs {
    background-image: url("/src/assets/img/banners/BannerHome-mobile.webp");
    height: 480px;
  }
  .interContainer {
    width: 360px;
    height: 1432px;
    margin-top: 80px;
  }
  .interTitleMean {
    width: 308px;
    height: 88px;
  }
  .interDiv {
    width: 295px;
    height: 1224px;
  }
  .insuranceContainerCards {
    flex-wrap: wrap;
    width: 340px;
    height: 1374px;
    justify-content: center;
  }
  .insuranceTitle {
    text-align: center;
  }
  .insuranceCard {
    width: 340px;
    height: 390px;
  }
}

@media (max-width: 376px) {
  .interContainer {
    width: inherit;
  }
  .insuranceTitle {
    width: 340px;
  }
}

.body-list a,
.blog-footer a,
.body-blog a {
  color: rgb(25, 118, 210);
  text-decoration: underline;
}

/* estilos defautlAdd */
.defaultAdd-MainBox{
    width: 100%; 
    position: static; 
    background:#002C81;
   
}

.textMovil-Menu {
  color: var(--black, #212121);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}