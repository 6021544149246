.container-datos {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
}

.iframe {
    height: 1100px;
    min-width: 500px;
}

.datos {
    background: var(--White, #fff);
    box-shadow: 0px 6px 0px 0px rgba(33, 33, 33, 0.05);
    display: flex;
    padding: 16px 8px;
    flex-direction: column;
    gap: 10px;
    min-width: 400px;
}

.title {
    color: var(--Black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 2 Bold */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
}

.subText {
    color: var(--Black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 2 */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.payment-info-container {
    display: flex;

    padding: 15px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    background: var(--azul-5, #f2fafc);
    margin-top: 24px;
}

.payment-info {
    height: 100px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--azul-5, #f2fafc);
    justify-content: center;
    width: 100%;
}

.payment-importe-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--azul-5, #f2fafc);
    justify-content: center;
    width: 100%;
}

.total {
    color: var(--Black, #212121);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Small Caption */
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
}

.amount {
    color: var(--Black, #212121);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* H4 */
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 114.286% */
    letter-spacing: 0.5px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.costo-anual {
    color: var(--black-50, #8f8f8f);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 2 */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}

@media (min-width: 899px) {
    .container-datos {
        flex-direction: row;
        justify-content: center;
    }

    .datos {
        margin-right: 10px;
    }
}

@media (max-width: 500px) {
    .iframe {
        min-width: 100%;
    }
}
