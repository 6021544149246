.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-top: 25px;
}

.container-loading {
  color: #fff;
  z-index: 1200;
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-loading {
  font-weight: 400;
  color: #595959;
  padding: 10px;
  margin-top: 10px;
  font-size: 18px;
  max-width: 550px;
  text-align: center;
}

@media (max-width: 560px) {
  
}