.promotions-container {
  padding-top: 3rem;
}

.promotions-img {
  width: 320px;
}

.promotions-title {
  color: #fff; /* Color del texto (blanco) */
  text-shadow: -1px -1px 0 #039ecc, 1px -1px 0 #039ecc, -1px 1px 0 #039ecc,
    1px 1px 0 #039ecc;
  font-size: 70px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.promotions-description {
  position: relative;
  background: var(--azul-inter, #039ecc);
  display: flex;
  height: 120px;
  padding: 24px 247px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;

  & p {
    font-weight: 700;
  }
}

.promotions-txt {
  font-size: 24px;
  font-weight: 700;
}

.py-10 {
  padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}


@media screen and (max-width: 500px) {

  .promotions-container {
    display: contents;
    text-align: center;
  }

  .promotions-mobile-img {
    display: flex;
    justify-content: center;
  }

  .promotions-img {
    width: 311px;
  }

  .promotions-title {
    padding-top: 1rem;
    font-size: 40px;
  }

  .promotions-description {
    width: auto;
    padding: 0 1rem;
  }
}