
.container-pago-exitoso {
  display: flex;
  flex-direction: column;
  align-items: center;
  translate: all 0.3s;
}

.pago-exitoso-titulo {
  font-weight: 700;
  font-size: 25px;
  width: 575px;
  text-align: center;
  color: #212121;
  margin-top: 15px;
  margin-bottom: 5px;
}

.pago-exitoso-info {
  font-size: 18px;
  font-weight: 500;
  width: 575px;
  text-align: center;
  color: #013654;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pago-exitoso-info-telefono {
  font-size: 18px;
  font-weight: 700;
}

.background-download-app {
  background-color: #c0e6f2;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 550px;
  position: relative;
  top: -175px;
  padding: 15px 20px;
  transition: all 0.3s;
}

.bg-app-title {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: #212121;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bg-app-subtitle {
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #595959;
  padding-top: 15px;
  padding-bottom: 15px;
}

.container-link-stores {
  display: flex;
  justify-content: center;
}

.container-link-stores a {
  margin: 10px 20px;
  -webkit-tap-highlight-color: transparent; /* Elimina el resaltado azul */
  outline: none;
}

.bg-app-appstore {
  height: 45px;
  width: auto;
  cursor: pointer;
  transition: all 0.2s;
}

.bg-app-playstore {
  height: 45px;
  width: auto;
  cursor: pointer;
  transition: all 0.2s;
}

.bg-app-appstore:hover, .bg-app-playstore:hover {
  transform: scale(1.07);
}

.bg-app-appstore:active, .bg-app-playstore:active {
  transform: scale(1.05);
}

.img-pago-ok {
  display: flex;
  height: auto;
  width: 250px;
  margin: 10px;
  transition: all 0.3s;
}

@media (max-width: 600px) {
  .pago-exitoso-info {
    width: 90%;
  }
  .pago-exitoso-titulo {
    width: 90%;
  }
  .background-download-app {
    width: 90%;
    top: 0px;
    transition: all 0.3s;
  }
}