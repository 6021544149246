.container-header-recibos {
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #C7C7C7;
  position: fixed;
  flex-grow: 0;
  right: 0;
  left: 0;
  top: 0;
  height: 64px;
  z-index: 1205;
}

.logo-inter-recibos {
  display: flex;
  height: 56px;
  width: auto;
}
