.container-PagoReno {
    display: flex;
    width: 100%;
    padding: 16px 10px;
    align-items: center;
    gap: 8px;
    background: var(--White, #fff);
    box-shadow: 0px 6px 0px 0px rgba(33, 33, 33, 0.05);
    margin-top: 6px;
}

.yellow-line {
    width: 4.152px;
    height: 52px;
    border-radius: 8px;
    background: var(--Yellow, #ffd60e);
    margin-right: 17px;
}

.title {
    color: var(--Black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

